.project-section {
    padding: 0 8% 0 8%;
    text-align: left;
    height: fit-content;
    margin-top: -5px;
}

.project-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 700;
    margin: 2rem auto;
    color: rgb(255,255,255);
    font-size: clamp(1.5rem, 5vw, 2.5rem);
}

.project-heading::after {
    position: relative;
    top: 5px;
    content: '';
    width: 40%;
    height: 2px;
    margin-left: 20px;
    background: rgba(100, 107, 112, 0.5);
    transition: width .7s ease-in;
  }

@media screen and (max-width: 768px) {
    .project-heading::after {
        margin-left: 0;
        width: 100%;
    }
}
    
