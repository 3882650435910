/* Navbar Section */
.navbar-wrapper {
    background-color: rgb(10, 25, 47);
}

.nav-link-list{ 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    margin: 0;
}

@media (max-width: 899px) {
    .nav-link-list {
      display: none;
    }
  }
.nav-link-list-drawer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    margin: 0;
}

.navbar-logo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.nav-link {
    height: 40px;
    margin-right: 15px;
    
}

.nav-link:hover {
    opacity: .7;
}

.drawer-link-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0 5%;
    margin: 10px auto;

}



