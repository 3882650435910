.project-content-right {
    margin: 0;
    padding: 2rem 0;
    height: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.project-content-left {
    margin: 0;
    padding: 2rem 0;
    height: 500px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.project-title {
    display: inline-block;
    max-width: fit-content;
    font-size: 2em;
    font-weight: 700;
    color: rgb(255, 255, 255);
}

.project-image-container {
    width: 400px;
    height: 400px;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: all 0.250s;
    z-index: 0;
}

.project-image-container:hover {
    opacity: 1;
}


.project-image-container::before {
    content: '';
    width: 400px;
    height:400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 5px;
    box-shadow: 
    -5px -5px 14px 1px rgba(255, 255, 255, 0.05),
    6px 6px 10px 2px rgba(0, 0, 0, .5);
}

.project-image-container-right:hover,
.project-image-container-left:hover {
    opacity: 1;
}

.project-image {
    aspect-ratio: 1/1;
    max-width: 100%;
    border-radius: 5px;
}


.project-wrapper-right {
    display: inline-block;
    position: relative;
    text-align: right;
    max-width: 60%;
    margin-left: -25px;
    z-index: 1;
}

.project-wrapper-left {
    display: inline-block;
    position: relative;
    text-align: left;
    max-width: 60%;
    margin-right: -25px;
    z-index: 1;
}

.project-summary {
    background-color: rgb(17, 34, 64);
    color: rgb(126, 130, 130);
    padding: 1% 1%;
    line-height: 1.6;
    border-radius: 5px;
}

.project-tech-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.project-tech-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.project-tech-right img,
.project-tech-left img {
    display: inline-block;
    height: 40px;
    margin-right: 2%;
    transform: translateY(-3px);
    padding: 0;
    margin-top: 10px;
}

.project-tech-right img:hover,
.project-tech-left img:hover {
    opacity: 0.5;
}

.project-tech-right li svg,
.project-tech-right li img,
.project-tech-left li svg,
.project-tech-left li img {
    list-style: none;
    display: inline-block;
    margin: 5px 0 0 5px;

}

.project-tech-right li,
.project-tech-left li {
    opacity: 1;
}

.project-tag:hover {
    border: 2px solid #ffbf38;
}

.project-tag {
    list-style: none;
    display: inline-block;
    padding-left: 10px;
    padding-right: 6px;
    margin: 0 5px 0 5px;
    border: 2px solid transparent;
    border-left: 2px solid #ffbf38;
    margin-top: 0;

}



@media screen and (max-width: 968px) {

    .project-image-container-left,
    .project-image-container-right {
        width: 100%;
        margin: 0;
        position: relative;
    }

    .project-image-container {
        aspect-ratio: 1/1;
        text-align: center;
        width: 100%;
        height: auto;
        opacity: 1;
    }

    .project-image-container::before {
        width: 100%;
        height:99%;
        top: 0;
        left: 0;
    }

    .project-image {
        width: 100%;
    }
    
    .project-wrapper-right,
    .project-wrapper-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        margin: auto;
        text-align: center;
    }

    .project-content-right,
    .project-content-left {
        display: flex;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .project-title {
        text-align: center;
    }

    .project-summary {
        text-align: center;
    }

    .project-tech-right,
    .project-tech-left {
        justify-content: center;
    }

    .project-tech-right img,
    .project-tech-left img {
        margin-right: 0;
    }

    .project-tag {
        margin-left: 15px;
        border-bottom: 1px solid #ffbf38;
        border-left: none;
        width: fit-content;
    }

    .external-project-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .project-tech-left,
    .project-tech-right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
    }
}
    
@media screen and (min-width: 968px) and (max-width: 1072px){
    .project-tech-left,
    .project-tech-right {
        grid-template-columns: repeat(1, 1fr);
    }

    .project-content {
        height: 600px;
        max-width: 850px;
    }

    .project-wrapper-left {
        max-width: 80%;
        transform: translate(0, 20%);
    }

    .project-wrapper-right {
        transform: translate(185px, 30%);
        max-width: 80%;
    }

    .project-image-container-left {
        transform: translate(150px, 0);
        width: 555px;
        position: absolute;
    }

    .project-image-container-right {
        width: 550px;
    }

    .project-image-container-left::before {
        position: relative;
    }


    .project-image-container-left::before,
    .project-image-container-right::before,
    .project-image-container-left,
    .project-image-container-right {
        opacity: 1;
        width: 555px;
    }

}
    
@media  screen and (min-width: 1072px) and (max-width: 1300px) {
    .project-image-container-left {
        transform: translate(200px, 0);
        width: 555px;
        position: absolute;
    }

    .project-image-container-left::before {
        transform: translate(200px, 0);
        
    }
    
}
