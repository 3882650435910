.spacer {
    aspect-ratio: 960/300;
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: none;
}

.top-layer {
    background-image: url(../../assets/Images/peaks-top.svg);
    background-color: rgb(10, 25, 47);
    margin-bottom: -2px;
}

.bottom-layer {
    background-image:url(../../assets/Images/peaks-bottom.svg);
    margin-top: -2px;
    margin-bottom: -2px;
}