.title {
    background-color: rgb(10, 25, 47);
    color: rgb(126, 130, 130);
    font-family: 'Roboto Mono', Arial, Helvetica, sans-serif;
    display: flex;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 500px;
    padding: 8% 8% 0 8%;
    margin-bottom: -1px;

}

.title-body {
    font-size: 1rem;
    max-width: 570px;
    margin-top: 30px;
}
