.outer {
    margin: 0;
    background-color: rgb(10, 25, 47);
    max-width: none;
    min-width: none;
    width: 100vw;
    border: none;

}

.inner {
    margin: auto;
    max-width: 1248px;
    min-width: none;
    overflow: hidden;
    border: none;
    
}